.home {
    color: inherit;
    font-size: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
  }
   
  @import url('https://fonts.googleapis.com/css?family=Cardo:400i|Rubik:400,700&display=swap');
   
   * {
       box-sizing: border-box;
  }
  
  .cardImage{
      width: 100%;
      height: 250px; 
  }
   
   
   .page-content {
       display: grid;
       grid-gap: 1rem;
       padding: 1rem;
       justify-content: space-around;
       font-family: var(--font-sans);
  }
   @media (min-width: 320px) {
       .page-content {
           grid-template-columns: repeat(1, 1fr);
           margin: 0px;
      }
  }
  @media (min-width: 768px) {
    .page-content {
        grid-template-columns: repeat(2, 1fr);
        margin: 30px 60px;
   }
}
   @media (min-width: 1200px) {
       .page-content {
           grid-template-columns: repeat(3, 1fr);
           margin: 30px 90px;
      }
      .cardImage{
          width: 100%;
          height: 250px; 
      }
  }
   .cardA {
       position: relative;
       /* display: flex; */
       align-items: flex-end;
       overflow: hidden;
       padding: 1rem; 
       width: 100%; 
       text-align: center;
       color: #1b4a78;
       background-color: whitesmoke;
       box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
  }
  .CardTitre{
       
  }
   @media (min-width: 600px) {
       .cardA {
           height: 350px;
      }
  }
   .cardA:before {
       content: '';
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 110%;
       background-size: cover;
       background-position: 0 0;
       transition: transform calc(var(--d) * 1.5) var(--e);
       pointer-events: none;
  }
   .cardA:after {
       content: '';
       display: block;
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 200%;
       pointer-events: none;
       background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.320) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.540) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.790) 100%);
  }
    
   
   .contentA {
       position: relative;
       display: flex;
       flex-direction: column;
       align-items: center; 
       padding: 1rem;
       transition: transform var(--d) var(--e);
       z-index: 1;
  }
   .contentA > * + * {
       margin-top: 1rem;
  }
   .titleA {
       font-size: 1.3rem;
       font-weight: bold;
       line-height: 1.2; 
  }
   .copy {
       font-family: var(--font-serif);
       font-size: 1.125rem;
       font-style: italic;
       line-height: 1.35;
  }
  .textABC {
      color: #1B4A78;
      font-weight: 700;
      align-content: left;
      font-size: 20px;
      text-transform: uppercase;
  }
   .btnA {
       cursor: pointer;
       margin-top: 1.5rem;
       padding: 0.75rem 1.5rem;
       font-size: 0.65rem;
       font-weight: bold;
       letter-spacing: 0.025rem;
       text-transform: uppercase;
       color: white;
       background-color: #1b4a78;
       border: none;
  }
   .btnA:hover {
       background-color: #1b4a78;
       color: white;
  }
   .btnA:focus {
       outline: 1px dashed yellow;
       outline-offset: 3px;
  }