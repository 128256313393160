.partenaires {
    max-width: auto;  
    /* overflow: auto; */
    color: inherit;
    font-size: 18px;  
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
      min-height:100px
    
  }
  .btn-wrapper{ position:absolute; bottom: -40px; width:91%;-ms-transform: translateY(-50%);
    transform: translateY(-50%);} 
    
    .btn-produit, .btn-produit:hover .btn-produit:active { 
      font-size: 16px;  
      margin: auto;
      background-color: white;
      border-color: white;
    }
  
    .content-margin{
      margin-top: 20px;
    }