 
 
div.jumbotron.carousel-hero {
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center;
  padding-top: 250px;
  padding-bottom: 250px;
  margin-bottom: 0;
  height: 90vh;
  border-radius: 0;
}

div.jumbotron.hero-nature {
   background-image: url("https://teledyne.ma/images/HomeSlider/4.webp")
  }
div.jumbotron.hero-photography {
  background-image: url("https://teledyne.ma/images/HomeSlider/5.webp")
}
div.jumbotron.hero-photography2 {
  background-image: url("https://teledyne.ma/images/HomeSlider/6.webp")
} 
div.jumbotron.hero-technology {
  background-image: url("https://teledyne.ma/images/HomeSlider/3.webp")
}

h1.hero-title, h2.hero-title, h3.hero-title {
  text-shadow: 0 3px 3px rgba(0,0,0,0.4);
  color: #fff;
  max-width: 500px;
  }

p.hero-subtitle {
  max-width: 500px;
  margin: 25px auto;
  padding: 10px 15px;
  font-weight : 500;
  font-size : 16px;
}

.plat {
  border-radius: 0px;
}