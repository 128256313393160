 

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-color: rgb(12,129,247);
  border-radius: 2px;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-radius: 2px;
  border-color: #fff;
}