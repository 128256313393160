.solution {
    color: inherit;
    font-size: 18px;  
    font-family: "Montserrat";
  }
  .btn-wrapper{ position:absolute; bottom: -40px; width:91%;-ms-transform: translateY(-50%);
    transform: translateY(-50%);} 
    
    .btn-test {
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width:88%;
      font-size: 13px;  
      margin: auto;
    }
    .card-test{
      border: none ;
      width:500px; 
    }
  
  
  
  
  @media screen and (min-width: 600px) {
  .owl-next {
      width: 15px;
      height: 100px;
      position: absolute;
      top: 25%;
      right: -35px;
      display: block !important;
      border:0px solid black;
  }
       .owl-prev {
      width: 15px;
      height: 100px;
      position: absolute;
      top: 25%;
        left:-35px;
      margin-left: -15px;
      display: block !important;
      border:0px solid black;
  }
       
  }
  @media screen and (max-width: 600px) {
  .owl-next {
      width: 15px;
      height: 100px;
      position: absolute;
      top: 25%;
      right: 15px;
      display: block !important;
      border:0px solid black;
  }
           .owl-prev {
      width: 15px;
      height: 100px;
      position: absolute;
      top: 25%; 
      margin-left: -15px;
      display: block !important;
      border:0px solid black;
  }
       
  }