.produit {
    color: inherit;
    font-size: 18px;  
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    
  }
  .btn-wrapper{ position:absolute; bottom: -40px; width:91%;-ms-transform: translateY(-50%);
    transform: translateY(-50%);} 
    
    .btn-text { 
          
      font-size: 13px;   
    }
    .card-test{
      width:500px; 
     
    }
  
  @media screen and (max-width: 600px) {
    .cardImage {
             width:100%;
         
    }
  }
  
  @media screen and (min-width: 600px) {
    .cardImage {
             width:100%; 
    }
  }