li,
ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

/*===========================
    2.HEADER css 
===========================*/
.appie-sticky.sticky {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1010;
  background: #fff;
  box-shadow: 0 15px 30px 0 #0000000f;
  padding-top: 20px;
  padding-bottom: 10px;
  animation: sticky 1.2s;
}

.appie-header-area {
  padding-top: 10px ;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
}
.appie-header-area.appie-header-page-area.appie-sticky.sticky {
  background: #0a44b9;
}
.appie-header-area.appie-header-error-page {
  box-shadow: 0px 10px 20px 0px rgba(14, 17, 51, 0.1);
  padding-top: 20px;
  padding-bottom: 10px;
}

.header-nav-box .appie-btn-box {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-nav-box .appie-btn-box {
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .header-nav-box .appie-btn-box {
    padding-right: 30px;
  }
}
.header-nav-box .appie-btn-box .login-btn {
  color: #0e1133;
  font-size: 15px;
  font-weight: 500;
}
.header-nav-box .appie-btn-box .login-btn i {
  padding-right: 6px;
}
@media (max-width: 767px) {
  .header-nav-box .appie-btn-box .main-btn {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-nav-box .appie-btn-box .main-btn {
    padding: 0 10px;
    display: inline-block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu {
    display: none;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu {
    display: none;
  }
}
.appie-header-main-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.appie-header-main-menu ul > li {
  display: inline-block;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul > li {
    margin-right: 14px;
  }
}
.appie-header-main-menu ul > li > a {
  font-size: 15px;
  font-weight: 500;
  color: #123463;
  line-height: 45px;
}
.appie-header-main-menu ul > li > a i {
  padding-left: 6px;
}
.appie-header-main-menu ul > li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: auto;
  min-width: 220px;
  max-width: 220px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
  text-align: left;
}
@media only screen and (min-width: 1200px) {
  .appie-header-main-menu ul > li .sub-menu {
    min-width: 400px;
    max-width: 400px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul > li .sub-menu {
    min-width: 300px;
    max-width: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
.appie-header-main-menu ul > li .sub-menu > li {
  position: relative;
  margin-left: 0;
  display: block;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
  margin-left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
.appie-header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
  color: #123463;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.appie-header-main-menu ul > li .sub-menu > li a {
  display: block;
  padding: 0px 30px;
  position: relative;
  color: #123463;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .appie-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
.appie-header-main-menu ul > li .sub-menu > li a i {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
.appie-header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
  display: inline-block;
}
.appie-header-main-menu ul > li .sub-menu > li a:hover {
  padding-left: 35px;
  background-color: whitesmoke;
}
.divider-menu{
  border-bottom: 2px solid #123463;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
@media (max-width: 767px) {
  .appie-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li {
  position: relative;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.appie-header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.appie-header-main-menu ul > li .sub-menu > li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.appie-header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
  color: #123463;
}
.appie-header-main-menu ul > li .sub-menu > li:hover > a {
  color: #123463;
}
.appie-header-main-menu ul > li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}

.main-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #123463;
  padding: 0 20px;
  font-size: 14px;
  line-height: 40px;
  border-radius: 6px;
  color: #fff !important;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #123463;
}
.main-btn:hover {
  background-color: #fff;
  color: #123463 !important;
  border-color: #123463;
}
.appie-section-title {
  padding-bottom: 25px;
}
.appie-section-title .appie-title {
  font-size: 44px;
  line-height: 54px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-section-title .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-section-title .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .appie-section-title .appie-title {
    font-size: 26px;
    line-height: 36px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-section-title .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.appie-section-title p {
  font-size: 18px;
  line-height: 28px;
  margin-top: 11px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-section-title p {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-section-title p {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .appie-section-title p {
    font-size: 15px;
  }
}
.appie-section-title .main-btn {
  border-radius: 30px;
  border: 2px solid #e7eaef;
  background: #fff;
  color: #0e1133;
}
.appie-section-title .main-btn:hover {
  background: #ff3e66;
  color: #fff;
  border-color: #ff3e66;
}
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 999;
  display: none;
}
.back-to-top a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: #123463;
  border-radius: 6px;
  color: #fff;
}

.off_canvars_overlay {
  width: 100%;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #232323;
  top: 0;
  transition: all linear 0.3s;
}
.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
  overflow: auto;
}

.offcanvas_menu {
  display: flex;
  flex-wrap: wrap;
  width: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_menu {
    display: block;
  }
}
@media (max-width: 767px) {
  .offcanvas_menu {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_menu_wrapper {
    width: 500px;
    margin-left: -500px;
  }
}
@media (max-width: 767px) {
  .offcanvas_menu_wrapper {
    width: 300px;
    margin-left: -300px;
  }
}
.offcanvas_menu_wrapper {
  margin-left: -500px;
  position: fixed;
  background: #fff;
  z-index: 9999;
  top: 0;
  width: auto;
  height: 100%;
  transition: 0.5s;
  left: 0;
  padding: 50px 15px 30px;
}
.offcanvas_menu_wrapper.active {
  margin-left: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.offcanvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}
.offcanvas_menu_wrapper .header-btn {
  margin-bottom: 30px;
}
.offcanvas_menu_wrapper .header-btn a {
  color: #222;
}
.offcanvas_menu_wrapper .header-btn a:hover {
  color: #222;
}

.offcanvas_main_menu li {
  position: relative;
  color: #123463;
}
.offcanvas_main_menu li:last-child {
  margin: 0;
}
.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}
.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 500;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #123463;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20 px;
  overflow: hidden;
  transition: all linear 0.65s;
}
.offcanvas_footer {
  padding-bottom: 50px;
  text-align: center;
}
.offcanvas_footer span a {
  font-size: 14px;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offcanvas_footer span a:hover {
  color: #123463;
}

.offcanvas_menu_wrapper.active .canvas_close {
  opacity: 1;
  visibility: visible;
}
.canvas_close {
  position: fixed;
  top: 10px;
  right: 0px;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
}
.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
  border: 1px solid #123463;
  border-radius: 10px;
  background: #123463;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.canvas_close a:hover {
  background: #123463;
  border-color: #123463;
  color: #fff;
}

.canvas_open a {
  font-size: 26px;
  width: 50px;
  height: 42px;
  display: block;
  line-height: 39px;
  text-align: center;
  border: 1px solid #232323;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.canvas_open a:hover {
  color: #123463;
  border-color: #123463;
}

.offcanvas-social {
  margin-bottom: 80px;
  margin-top: 40px;
}
.offcanvas-social ul li {
  display: inline-block;
  margin: 5px;
}
.offcanvas-social ul li a {
  font-size:24px;
  text-align: center;
  line-height: 38px;
  color: #123463;
  border: 1px solid #eaeaea;
  border-radius: 20%;
  padding: 5px;
  margin: 10px 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.offcanvas-social ul li a:hover {
  background: #123463;
  border-color: #123463;
  color: #fff;
}

.toggle-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #0e1133;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-position: center center;
  background-repeat: no-repeat;
}

.preloader-close {
  position: fixed;
  z-index: 999999;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  right: 40px;
  bottom: 40px;
  font-weight: 600;
  line-height: 60px;
  background: #123463;
  padding: 0 15px;
  border-radius: 5px;
}

.loader-wrap {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.loader-wrap .layer-one {
  position: absolute;
  left: 0%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-two {
  position: absolute;
  left: 33.3333%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-three {
  position: absolute;
  left: 66.6666%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f4f9ff;
}

/*===========================
    SEARCH css 
===========================*/
.search-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999999;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}
.search-box .search-header .search-title {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.search-box .search-header .search-close button {
  background: none;
  border: 0;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding-right: 35px;
  position: relative;
}
.search-box .search-header .search-close button span {
  width: 21px;
  height: 2px;
  background-color: #fff;
  display: block;
  position: absolute;
  right: 0;
}
.search-box .search-header .search-close button span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.search-box .search-header .search-close button span:nth-child(2) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 13px;
}
.search-box .search-body {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.search-box .search-body .search-form {
  position: relative;
}
.search-box .search-body .search-form input {
  width: 100%;
  border: 0;
  height: 80px;
  padding-left: 30px;
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 20px;
  color: #707070;
}
.search-box .search-body .search-form input::placeholder {
  color: #707070;
  opacity: 1;
}
.search-box .search-body .search-form button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  font-size: 36px;
  color: #707070;
  background: none;
  border: 0;
}
.search-box .search-footer {
  padding-bottom: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.search-box .search-footer .search-footer-content h4 {
  color: #707070;
  font-size: 24px;
}
.search-box .search-footer .search-footer-content p {
  color: #222;
  font-size: 16px;
}

.search-box.open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
/*===========================
    10.APPIE FOOTER css 
===========================*/
.appie-footer-area {
  background: #eef1f6;
  padding-top: 50px;
  padding-bottom: 60px;
}
.appie-footer-area.appie-footer-about-area {
  padding-top: 230px;
}


.footer-about-widget {
  padding-right: 70px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .footer-about-widget {
    text-align: center;
    padding-right: 0;
  }
}
.footer-about-widget p {
  padding-top: 25px;
}
.footer-about-widget > a {
  font-size: 15px;
  font-weight: 500;
  color: #0e1133;
  margin-top: 15px;
}
.footer-about-widget > a i {
  padding-left: 6px;
}
.footer-about-widget .social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-about-widget .social ul li {
  display: inline-block;
}
.footer-about-widget .social ul li a {
  height: 36px;
  width: 36px;
  text-align: center;
  background: #fff;
  color: #0e1133;
  border-radius: 6px;
  line-height: 36px;
  margin-right: 5px;
}
.footer-about-widget .social ul li a:hover {
  background: #123463;
  color: #fff;
}

.footer-navigation {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .footer-navigation {
    text-align: center;
  }
}
.footer-navigation .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
}
.footer-navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-navigation ul li a {
  color: #505056;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 6px;
}
.footer-navigation ul li a:hover {
  color: #123463;
}

.footer-widget-info {
  overflow: auto;
  margin-bottom: 30px;
  margin-top: 30px;
}
.footer-widget-info ul {
  text-align: start;
  padding: 10px;
}
@media (max-width: 767px) {
  .footer-widget-info {
    text-align: center;
  }
}
.footer-widget-info .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 18px;
}
.footer-widget-info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-widget-info ul li {
  color: #123463;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.footer-copyright {
  padding-top: 35px;
  border-top: 1px solid #e1e5ec;
  margin-top: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .footer-copyright {
    display: block !important;
    text-align: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-copyright .apps-download-btn {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer-copyright .apps-download-btn {
    margin-bottom: 20px;
  }
}
.footer-copyright .apps-download-btn ul {
  padding: 0;
  list-style-type: none;
}
.footer-copyright .apps-download-btn ul li {
  display: inline-block;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .footer-copyright .apps-download-btn ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-copyright .apps-download-btn ul li {
    margin-bottom: 0px;
  }
}
.footer-copyright .apps-download-btn ul li a {
  background: #fff;
  color: #0e1133;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 2px solid #fff;
}
.footer-copyright .apps-download-btn ul li a i {
  padding-right: 6px;
}
.footer-copyright .apps-download-btn ul li a:hover {
  background: transparent;
  color: #0e1133;
  border-color: #e7eaef;
}

.appie-error-area {
  padding-top: 250px;
}

.appie-error-content span {
  display: block;
  font-size: 50px;
  color: #db0f30;
  font-weight: 800;
  padding-top: 40px;
}
.appie-error-content .title {
  font-size: 50px;
  font-weight: 800;
  color: #0e1133;
  padding-bottom: 11px;
}
.appie-error-content p {
  line-height: 26px;
  color: #505056;
  font-size: 16px;
  padding: 0 50px;
}
.appie-error-content a {
  border: 2px solid #123463;
  line-height: 45px;
  padding: 0 35px;
  border-radius: 6px;
  margin-top: 30px;
}

.appie-signup-box {
  padding: 63px 100px 60px;
  border-radius: 10px;
  box-shadow: 0px 40px 50px 0px rgba(0, 24, 72, 0.3);
  overflow: hidden;
  position: relative;
  margin-bottom: -160px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box {
    padding: 30px 50px;
  }
}
@media (max-width: 767px) {
  .appie-signup-box {
    padding: 30px;
  }
}
.appie-signup-box .thumb {
  position: absolute;
  right: 70px;
  bottom: 30px;
  width: 160px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box .thumb {
    display: none;
  }
}
@media (max-width: 767px) {
  .appie-signup-box .thumb {
    display: none;
  }
}
.appie-signup-box span {
  color: #fff;
  font-weight: 500;
}
.appie-signup-box .title {
  font-size: 46px;
  color: #fff;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .appie-signup-box .title {
    font-size: 30px;
  }
}
.appie-signup-box form {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box form {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .appie-signup-box form {
    flex-direction: column;
  }
}
.appie-signup-box form .input-box {
  margin-right: 10px;
  margin-top: 10px;
}
.appie-signup-box form .input-box input {
  width: 300px;
  background: #fff;
  line-height: 55px;
  border-radius: 6px;
  padding-left: 30px;
  border: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-signup-box form .input-box input {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .appie-signup-box form .input-box input {
    width: 100%;
  }
}
.appie-signup-box form .input-box button {
  background: #123463;
  line-height: 55px;
  border: 0;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  font-weight: 500;
}

.appie_checkbox_common {
  margin-top: 15px;
}

.appie_checkbox_common label {
  color: #fff;
  font-size: 14px;
  margin-bottom: 12px;
  cursor: pointer;
  font-weight: 400;
}

.appie_checkbox_common label a {
  text-decoration: underline;
  color: #b3b6bc;
}

.appie_checkbox_common input[type="checkbox"] {
  display: none;
}

.appie_checkbox_common input[type="checkbox"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -1px 15px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
}

.appie_checkbox_common input[type="checkbox"] + label span:before {
  color: #fff;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  position: absolute;
  text-align: center;
  left: 3px;
  top: -1px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.appie_checkbox_common input[type="checkbox"]:checked + label span {
  background: #2079ff;
  border-color: #2079ff;
}

.appie_checkbox_common input[type="checkbox"]:checked + label span:before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.appie_checkbox_common input[type="checkbox"] + label span,
.appie_checkbox_common input[type="checkbox"]:checked + label span {
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.appie_checkbox_common.checkbox_style2 input[type="checkbox"] + label span {
  border-radius: 3px;
}


.containerx{
  width: auto;
  margin: auto;
}

.containerx .card{
  position: relative;
  cursor: pointer;
  border: 0px;
  padding: 0 10px;
  
} 

.containerx .card .face{
  max-width: 400px;
  min-width: 250px;
  height: 180px;
  transition: 0.5s;
}

.containerx .card .face.face1{
  position: relative;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(50px);
  border-radius: 5%;
  
}

.containerx .card:hover .face.face1{
  border: 3px solid #123463;
  transform: translateY(0);
}

.containerx .card .face.face1 .content{
  opacity: 1;
  transition: 0.5s;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.containerx .card:hover .face.face1 .content{
  opacity: 1;
}

.containerx .card .face.face1 .content img{
  max-width: 100px;
}

.containerx .card .face.face1 .content h6{
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
}
@media (min-width: 1400px) {
  .containerx .card .face.face2{
    max-height: 160px;
  }
}
.containerx .card .face.face2{
  background: transparent;
  box-sizing: border-box;
  transform: translateY(-150px);
  padding-top: 5px;
}

.containerx .card:hover .face.face2{
  transform: translateY(0);
}
.containerx .card:hover .face.face2 .content p{
  color: #123463
}
.containerx .card .face.face2 .content p{
  margin: 0;
  padding: 0;
  color: white;
  font-size: 14px;
}

.containerx .card .face.face2 .content a{
  margin: 10px 0 0;
  display:  inline-block;
  font-weight: 600;
  color: white;
  background: #123463;
}
@media (max-width: 1400px) {
  .containerx .card .face.face1{
    border: 3px solid #123463;
    transform: translateY(0);
  }
  .containerx .card .face.face2{
    transform: translateY(0);
  }
  .containerx .card .face.face2 .content p{
    color: #123463
  }
}