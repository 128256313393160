.noservices {
  color: inherit;
  font-size: 18px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.box {
  padding: 60px 0px;
}

.box-part {
  background: #eee;
  border-radius: 0;
  padding: 60px 10px;
  margin: 30px 0px;
}
.text {
  margin: 20px 0px;
}
.titlep {
  margin-top: 10px;
}
h4 {
  text-transform: capitalize;
  color: #1b4a78;
  font-weight: 600;
}

.fa {
  color: #4183d7;
}

.box-shadow-hover:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.pointer {
  cursor: pointer;
}

.img0 {
  width: auto;
  max-height: 100px;
  margin-bottom: 20px;
}

.backgroundcolor {
  background: linear-gradient(180deg, white 70%, #12304e 30%);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (min-width: 320px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    margin: 0px;
  }
}
@media (min-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    margin: 0px;
  }
}
@media (min-width: 1400px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
    margin: 0px;
  }
}
