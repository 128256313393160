 
h1.hero-title, h2.hero-title, h3.hero-title {
  text-shadow: 0 3px 3px rgba(0,0,0,0.4);
  color: #fff; 
}

p.hero-subtitle {
  max-width: 500px; 
  padding: 10px 15px;
 font-weight : 500;
font-size : 16px;

}

@media only screen and (min-width: 601px) {
.TitrePadding{ 
	padding-top:120px;
}
}

.center {
  padding: 70px 0; 
}
@media only screen and (max-width: 600px) {
.TitrePadding{ 
	padding-top:70%
}
}